import VueScrollTo from 'vue-scrollto'

import * as types from './mutation-types'
import { PURCHASE_MODE_ALL } from './state'

function handleKitError (e, dispatch, store) {
  switch (e.code) {
    case store.$PE.Error.Codes.OTHER:
      if (e.message === 'Bad Request') {
        dispatch('addToast', {
          text: 'Vous avez déjà effectuer la revue de ce ticket.',
          type: 'danger',
          dismissAfter: 4000,
        }, { root: true })
      } else {
        dispatch('addToastGenericError', null, { root: true })
        store.$airbrake.notifyFromKit(e, { locator: 'review/fetch#1' }, { store })
      }
      break
    default:
      dispatch('addToastGenericError', null, { root: true })
      store.$airbrake.notifyFromKit(e, { locator: 'review/fetch#2' }, { store })
  }
}

export function reset ({ commit }) {
  commit(types.RESET)
}

export async function fetch ({ commit, dispatch }, receiptUuid) {
  // commit(types.RESET)
  commit(types.SET_IS_REVIEW_LOADING, true)
  try {
    const result = await this.$PE.client.showReceiptReview({ receiptUuid })
    commit(types.SET_RECEIPT, result.receipt)
    commit(types.SET_IS_REVIEW_LOADED, true)
  } catch (e) {
    // TODO: better error handling (specific error code)
    if (e instanceof this.$PE.Error) {
      handleKitError(e, dispatch, this)
    } else {
      this.$airbrake.notifyInAsync(e, { locator: 'review/fetch#3' }, { store: this })
      dispatch('addToastGenericError', null, { root: true })
    }
    this.$router.push('/homes/registered')
  } finally {
    commit(types.SET_IS_REVIEW_LOADING, false)
  }
}

export async function togglePurchasesMode ({ state, commit }, mode) {
  if (state.purchasesMode === mode) {
    commit(types.SET_PURCHASES_MODE, PURCHASE_MODE_ALL)
  } else {
    commit(types.SET_PURCHASES_MODE, mode)
  }
}

export function showReceiptImage ({ state, commit }) {
  commit(types.OPEN_MODAL, state.MODALS.IMAGE)
}

export async function handlePurchaseClick ({ state, commit, dispatch, rootState }, purchase) {
  commit(types.SET_SELECTED_PURCHASE, purchase)

  if (!purchase.is_probably_eligible_to_program) {
    return commit(types.OPEN_MODAL, state.MODALS.SCAN)
  }

  const identifier = purchase.is_probably_eligible_to_program[0].card_identifier

  if (!rootState.shop.catalog) {
    await dispatch('shop/fetch', null, { root: true })
  }

  if (rootState.shop.catalog.loyalty_card.identifier === identifier) {
    commit(types.OPEN_MODAL, state.MODALS.TRUSTING)
  } else {
    commit(types.OPEN_MODAL, state.MODALS.SCAN)
  }
}

export async function claimOnPurchase ({ state, commit, dispatch }, ean) {
  commit(types.SET_IS_CLAIMING_ON_PURCHASE, true)

  let res
  try {
    res = await this.$PE.client.claimOnPurchase({
      ean,
      purchaseId: state.selectedPurchase.purchase_id,
    })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'review#claimOnPurchase' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_CLAIMING_ON_PURCHASE, false)
  }

  // TODO: feature provide_picture if needed
  commit(types.UPDATE_PURCHASES, res.purchases)
  dispatch('closeModal')
  dispatch('addToast', {
    text: 'Demande enregistrée',
    type: 'success',
    dismissAfter: 4000,
  }, { root: true })
}

export async function trustingClaimOnPurchase ({ state, commit, dispatch }) {
  commit(types.SET_IS_CLAIMING_ON_PURCHASE, true)

  let res
  try {
    res = await this.$PE.client.trustingClaimOnPurchase({
      purchaseId: state.selectedPurchase.purchase_id,
      cardIdentifier: state.selectedPurchase.is_probably_eligible_to_program[0].card_identifier,
    })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'review#trustingClaimOnPurchase' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_CLAIMING_ON_PURCHASE, false)
  }

  // TODO: feature provide_picture if needed
  commit(types.UPDATE_PURCHASES, res.purchases)
  dispatch('closeModal')
  dispatch('addToast', {
    text: 'Demande enregistrée',
    type: 'success',
    dismissAfter: 4000,
  }, { root: true })
}

export async function claimOnReceipt ({ state, commit, dispatch }, ean) {
  commit(types.SET_IS_CLAIMING_ON_RECEIPT, true)

  let res
  try {
    res = await this.$PE.client.claimOnReceipt({
      ean,
      receiptUuid: state.receipt.receipt_uuid,
    })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'review#claimOnReceipt' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_CLAIMING_ON_RECEIPT, false)
  }

  // TODO: feature provide_picture if needed
  commit(types.UPSERT_ORPHAN_CLAIM, res.claim)
  dispatch('closeModal')
  dispatch('addToast', {
    text: 'Demande enregistrée',
    type: 'success',
    dismissAfter: 4000,
  }, { root: true })
}

export function openModal ({ commit }, modalName) {
  commit(types.OPEN_MODAL, modalName)
}

export function closeModal ({ commit }) {
  commit(types.OPEN_MODAL, null)
}

export async function validateReceiptReview ({ state, commit, dispatch }) {
  commit(types.SET_IS_VALIDATING, true)

  try {
    await this.$PE.client.validateReceiptReview({
      receiptUuid: state.receipt.receipt_uuid,
    })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'review#validateReceiptReview' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_VALIDATING, false)
  }

  // TODO: take care of return
  // { collector_cards: [] }
  await dispatch('timeline/reset', null, { root: true })
  this.$router.push('/homes/registered')
  dispatch('addToast', {
    text: 'Ticket validé',
    type: 'success',
    dismissAfter: 4000,
  }, { root: true })
}

export async function reportOnReceipt ({ state, commit, dispatch }, message) {
  commit(types.SET_IS_REPORTING_ON_RECEIPT, true)

  try {
    await this.$PE.client.reportOnReceipt({
      message,
      receiptUuid: state.receipt.receipt_uuid,
    })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'review#reportOnReceipt' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_REPORTING_ON_RECEIPT, false)
  }

  dispatch('closeModal')
  dispatch('addToast', {
    text: 'Message envoyé !<br>Notre équipe se penche sur la question ;)',
    type: 'success',
    dismissAfter: 4000,
  }, { root: true })
}

export async function fetchAvailableEarnedCoupons ({ state, commit, dispatch }, message) {
  commit(types.SET_IS_LOADING_COUPONS, true)

  let res
  try {
    res = await this.$PE.client.getAvailableEarnedCoupons()
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'review#fetchAvailableEarnedCoupons' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_LOADING_COUPONS, false)
  }

  commit(types.SET_AVAILABLE_COUPONS, res.coupons)
}

export function resetAvailableEarnedCoupons ({ commit }, message) {
  commit(types.RESET_COUPONS)
}

export async function claimEarnedCouponOnReceipt ({ state, commit, dispatch }, coupon) {
  const receiptUuid = state.receipt.receipt_uuid
  commit(types.SET_IS_CLAIMING_ON_RECEIPT, true)

  try {
    await this.$PE.client.claimEarnedCouponOnReceipt({ coupon, receiptUuid })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'review#claimEarnedCouponOnReceipt' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_CLAIMING_ON_RECEIPT, false)
  }

  await dispatch('fetch', receiptUuid)
  VueScrollTo.scrollTo('body')
  dispatch('closeModal')
  dispatch('addToast', {
    text: 'Votre demande a bien été ajoutée.',
    type: 'success',
    dismissAfter: 4000,
  }, { root: true })
}
