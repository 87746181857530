import { extend, localize } from 'vee-validate'
// import fr from 'vee-validate/dist/locale/fr.json'
import {
  alpha_spaces, // eslint-disable-line camelcase
  numeric,
  required,
  email,
} from 'vee-validate/dist/rules'

localize('fr')
// localize({ fr })

extend('alpha_spaces', alpha_spaces)
extend('numeric', numeric)
extend('required', required)
extend('email', email)

extend('zipcode', value => /^\d{5}$/.test(value))

localize({
  fr: {
    messages: {
      alpha_spaces: 'Seuls lettres et espaces sont autorisés',
      numeric: 'Seuls les chiffres sont autorisés',
      email: "Ce n'est pas un mail valide",
      required: 'Merci de remplir ce champs',
      zipcode: 'Doit contenir 5 chiffres',
      // max: (_, { length }) => `this field must have no more than ${length} characters`
    },
  },
})
