import * as types from './mutation-types'

import { CONTEXT_BUY, CONTEXT_CANCEL } from './mutations'

// strings from backend
const REWARD_KIND_COUPON = 'Coupon'
const REWARD_KIND_GOODY = 'Goody'

export function reset ({ commit }) {
  commit(types.RESET)
}

export async function fetch ({ commit, dispatch, rootState }) {
  // commit(types.RESET)
  commit(types.SET_IS_SHOP_LOADING, true)

  let catalogs
  try {
    catalogs = await this.$PE.client.getCatalogs()
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'shop#fetch' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.SET_IS_SHOP_LOADING, false)
  }

  const catalog = findCorrectCatalog(catalogs, this, rootState)
  commit(types.SET_CATALOG, catalog)
  commit(types.SET_COUPONS, catalog.coupons)
  dispatch('server/setPoints', catalog.loyalty_card.available_points, { root: true })
  commit(types.SET_IS_SHOP_LOADED, true)
}

// TODO: after some time without error
// just use `catalogs[0]` instead
function findCorrectCatalog (catalogs, store, rootState) {
  let catalog
  switch (catalogs.length) {
    case 0:
      return // will raise
    case 1:
      catalog = catalogs[0]
      if (catalog.owner_type !== 'LoyaltyCard' || catalog.loyalty_card.name !== rootState.server.programName) {
        store.$airbrake.notifyInAsync(new Error('catalog mismatch'), { locator: 'findCorrectCatalog 1' }, { store })
      }
      return catalog
    default:
      store.$airbrake.notifyInAsync(new Error('multiple catalogs'), { locator: 'findCorrectCatalog 2' }, { store })
      catalog = catalogs.find(c => (
        c.owner_type === 'LoyaltyCard' &&
        c.loyalty_card.name === rootState.server.programName
      ))
      if (!catalog) {
        store.$airbrake.notifyInAsync(new Error('no catalog'), { locator: 'findCorrectCatalog 3' }, { store })
      }
      return catalog
  }
}

export async function buyReward ({ dispatch }, reward) {
  switch (reward.kind) {
    case REWARD_KIND_COUPON:
      return dispatch('buyCoupon', reward)
    case REWARD_KIND_GOODY:
      return dispatch('neededFieldsForGoody', reward)
    default:
      this.$airbrake.notifyInAsync(
        new Error(`unknown kind: ${reward.kind}`),
        { locator: 'shop#buyReward' },
        { store: this }
      )
      dispatch('addToastGenericError', null, { root: true })
  }
}

export async function neededFieldsForGoody ({ commit, dispatch }, goody) {
  commit(types.UPDATE_REWARD_KEY, { reward: goody, key: 'isFetchingNeededFields', value: true })

  let response
  try {
    response = await this.$PE.client.neededFieldsForGoody({ goody })
  } catch (e) {
    this.$airbrake.notifyFromKit(
      e,
      { locator: 'shop#neededFieldsForGoody', goodyIdentifier: goody.identifier },
      { store: this }
    )
    dispatch('addToastGenericError', null, { root: true })
    commit(types.UPDATE_REWARD_KEY, { reward: goody, key: 'isFetchingNeededFields', value: false })
    return
  }

  commit(types.SET_NEEDED_FIELDS_FOR_GOODY, response.fields)
  commit(types.SELECT_REWARD, goody)
  commit(types.SET_IS_MODAL_OPEN_FOR_GOODY_USER_INFORMATIONS, true)
  commit(types.UPDATE_REWARD_KEY, { reward: goody, key: 'isFetchingNeededFields', value: false })
}

export async function orderGoody ({ commit, dispatch, state, rootState }, userInformations) {
  commit(types.SET_IS_ORDERING_GOODY, true)

  try {
    await this.$PE.client.orderGoody({ goody: state.selectedCoupon, userInformations })
  } catch (e) {
    this.$airbrake.notifyFromKit(
      e,
      { locator: 'shop#orderGoody', goodyIdentifier: state.selectedCoupon.identifier },
      { store: this }
    )
    dispatch('addToastGenericError', null, { root: true })
    commit(types.SET_IS_ORDERING_GOODY, false)
    return
  }

  dispatch('server/decrementPoints', state.selectedCoupon.value, { root: true })
  commit(types.UPDATE_REWARDS_VALUES, {
    availablePoints: rootState.server.points,
    context: CONTEXT_BUY,
  })
  commit(types.SET_NEEDED_FIELDS_FOR_GOODY, null)
  commit(types.SELECT_REWARD, null)
  commit(types.SET_IS_ORDERING_GOODY, false)
  commit(types.SET_IS_MODAL_OPEN_FOR_GOODY_USER_INFORMATIONS, false)
}

export async function buyCoupon ({ commit, dispatch, rootState }, coupon) {
  commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isBuying', value: true })

  try {
    await this.$PE.client.buyCoupon({ coupon })
  } catch (e) {
    this.$airbrake.notifyFromKit(
      e,
      { locator: 'shop#buyCoupon', couponIdentifier: coupon.identifier },
      { store: this }
    )
    dispatch('addToastGenericError', null, { root: true })
    commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isBuying', value: false })
    return
  }

  dispatch('server/decrementPoints', coupon.value, { root: true })
  commit(types.UPDATE_REWARDS_VALUES, {
    availablePoints: rootState.server.points,
    context: CONTEXT_BUY,
  })
  commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isBuying', value: false })
  commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isCancellable', value: true })
}

export async function cancelCoupon ({ commit, dispatch, rootState }, coupon) {
  commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isCancelling', value: true })

  try {
    await this.$PE.client.cancelCoupon({ coupon })
  } catch (e) {
    this.$airbrake.notifyFromKit(
      e,
      { locator: 'shop#cancelCoupon', couponIdentifier: coupon.identifier },
      { store: this }
    )
    dispatch('addToastGenericError', null, { root: true })
    commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isCancelling', value: false })
    return
  }

  dispatch('server/incrementPoints', coupon.value, { root: true })
  commit(types.UPDATE_REWARDS_VALUES, {
    availablePoints: rootState.server.points,
    context: CONTEXT_CANCEL,
  })
  commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isCancelling', value: false })
  commit(types.UPDATE_REWARD_KEY, { reward: coupon, key: 'isCancellable', value: false })
}

export async function toggleGoal ({ commit, dispatch }, reward) {
  commit(types.UPDATE_REWARD_KEY, { reward, key: 'isGoalToggling', value: true })

  try {
    if (reward.is_next_goal) {
      await this.$PE.client.cancelNextGoal({ coupon: reward })
    } else {
      await this.$PE.client.selectNextGoal({ coupon: reward })
    }
  } catch (e) {
    this.$airbrake.notifyFromKit(
      e,
      { locator: 'shop#toggleGoal', rewardIdentifier: reward.identifier },
      { store: this }
    )
    dispatch('addToastGenericError', null, { root: true })
    return
  } finally {
    commit(types.UPDATE_REWARD_KEY, { reward, key: 'isGoalToggling', value: false })
  }

  // performance : MAJ done in client instead of via HTTP call
  // await dispatch('reloadCatalog')
  commit(types.TOGGLE_REWARD_AS_NEXT_GOAL, reward)
  commit(types.UPDATE_REWARD_SEPARATORS)
}

export function timeoutCancelReward ({ commit }, reward) {
  commit(types.UPDATE_REWARD_KEY, { reward, key: 'isCancellable', value: false })
}

export async function reloadCatalog ({ state, commit, dispatch }) {
  let coupons
  try {
    coupons = await this.$PE.client.getCardCatalog({ card: state.catalog.loyalty_card })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'shop#reloadCatalog' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    return
  }

  commit(types.SET_COUPONS, coupons)
}

export function showModalForDetails ({ commit }, reward) {
  commit(types.SELECT_REWARD, reward)
  commit(types.SET_IS_MODAL_OPEN_FOR_DETAILS, true)
}

export function closeModalForDetails ({ commit }) {
  commit(types.SELECT_REWARD, null)
  commit(types.SET_IS_MODAL_OPEN_FOR_DETAILS, false)
}

export function closeModalForGoodyUserInformations ({ commit }) {
  commit(types.SELECT_REWARD, null)
  commit(types.SET_IS_MODAL_OPEN_FOR_GOODY_USER_INFORMATIONS, false)
}
