import { ADD_TOAST_MESSAGE } from 'vuex-toast'

import logger from '../utils/logger'

export const state = () => ({
  disableJqueryErrors: false,
})
export const mutations = {
  disableJqueryErrors (state) {
    state.disableJqueryErrors = true
  },
}

export const actions = {
  // TODO: move rewards to module partner
  logout ({ state, commit, dispatch }) {
    // old stuff
    dispatch('auth/reset')
    dispatch('credentials/reset')

    dispatch('server/setPoints', null)

    // new stuff
    dispatch('card/reset')
    dispatch('earned-coupons/reset')
    dispatch('pot/reset')
    dispatch('review/reset')
    dispatch('shop/reset')
    dispatch('timeline/reset')
  },

  // type should be in [info, success, warning, danger]
  addToast ({ dispatch }, { text, type, dismissAfter }) {
    dispatch(`toast/${ADD_TOAST_MESSAGE}`, { text, type, dismissAfter }, { root: true })
  },
  addToastGenericError ({ dispatch }) {
    dispatch(`toast/${ADD_TOAST_MESSAGE}`, {
      text: 'Une erreur est survenue.<br>Nous en avons été informés.',
      type: 'danger',
      dismissAfter: 4000,
    }, { root: true })
  },

  async nuxtServerInit ({ commit, dispatch }, ctx) {
    const { req } = ctx

    logger.debug('req.session', req.session)

    commit('trackers/setIds', req.trackers)

    if (req.urlParsed.pathname.startsWith('/errors')) { return }
    if (!req.initialQuery || !req.initialQuery.clientId) { return }

    if (req.disableJqueryErrors) {
      commit('disableJqueryErrors')
    }

    const desc = req.partnerDetails

    // TODO: move to module partner
    // TODO: store as one object ?
    commit('server/setProgramName',     desc.loyalty_program.name)
    commit('server/setProgramId',       desc.loyalty_program.id)
    commit('server/setProgramTermsUrl', desc.loyalty_program.terms_url)
    commit('server/setPrimaryColor',    desc.loyalty_program.dominant_color)
    commit('server/setWelcomingPoints', desc.loyalty_program.welcoming_points)
    commit('server/setRewards',         desc.loyalty_program.rewards)
    commit('server/setHeaderAssetUrl',  desc.loyalty_program.header_asset_url)
    commit('server/setHeaderAsset2xUrl', desc.loyalty_program.header_asset_2x_url)
    commit('server/setHeaderAsset3xUrl', desc.loyalty_program.header_asset_3x_url)
    commit('server/setEmailForDigitalReceipts', desc.loyalty_program.email_for_digital_receipts)

    await dispatch('auth/configureUser', {
      user: req.session.user,
      partner: req.session.partners && req.session.partners[req.initialQuery.clientId],
      fidmarques: req.session.fidmarques,
      userExternalEmail: req.initialQuery.userExternalEmail,
    })

    // done

    commit('auth/configHasBeenSet')
  },
}
