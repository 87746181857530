import resetter from '../utils/store/resetter'
import { defineSetters } from '../utils/store/utils'
import stripAccents from '../utils/functions/stripAccents'

const CLOSING_PROGRAM_NAMES = ['Andros', 'Lotus']

export const state = () => ({
  primaryColor: null,
  programName: null,
  programId: null,
  programTermsUrl: null,
  welcomingPoints: null,
  rewards: [],
  headerAssetUrl: null,
  headerAsset2xUrl: null,
  headerAsset3xUrl: null,
  emailForDigitalReceipts: null,

  // only one not set by backend but easier to reason about
  points: null,
})

// https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t
export function inIframe () {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const getters = {
  programSlug: state => stripAccents(state.programName).replace(/\s/g, ''),
  pointsColor: (_, getters) => getters.isAndros ? '#00843d' : null,
  inverse: (_, getters) => getters.isAndros,
  isProgramClosing: state => CLOSING_PROGRAM_NAMES.includes(state.programName),
  isSubscriptionDisabled: (_, getters) => getters.isProgramClosing,
  isAndros: state => state.programName === 'Andros',
}

export const actions = {
  reset ({ commit }) {
    commit('reset')
  },

  incrementPoints ({ state, dispatch }, increment) {
    dispatch('setPoints', state.points + increment)
  },
  decrementPoints ({ state, dispatch }, decrement) {
    dispatch('setPoints', state.points - decrement)
  },

  setPoints ({ commit, getters, rootState, rootGetters }, points) {
    commit('setPoints', points)

    if (typeof window === 'undefined' || !inIframe()) return
    if (!rootState.route) return

    window.parent.postMessage(
      {
        function: 'setPoints',
        params: {
          points,
          color: getters.pointsColor,
        },
      },
      rootState.route.query.origin
    )
  },
}

export const mutations = {
  ...defineSetters(state()),

  setPoints (state, points) {
    state.points = points
  },

  reset: resetter(state),
}
