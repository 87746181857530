export const RESET = 'RESET'

export const SET_IS_SHOP_LOADING = 'SET_IS_SHOP_LOADING'
export const SET_IS_SHOP_LOADED = 'SET_IS_SHOP_LOADED'

export const SET_CATALOG = 'SET_CATALOG'
export const SET_COUPONS = 'SET_COUPONS'
export const UPDATE_REWARD_KEY = 'UPDATE_REWARD_KEY'
export const UPDATE_REWARDS_VALUES = 'UPDATE_REWARDS_VALUES'
export const UPDATE_REWARD_SEPARATORS = 'UPDATE_REWARD_SEPARATORS'
export const TOGGLE_REWARD_AS_NEXT_GOAL = 'TOGGLE_REWARD_AS_NEXT_GOAL'

export const SELECT_REWARD = 'SELECT_REWARD'
export const SET_IS_MODAL_OPEN_FOR_DETAILS = 'SET_IS_MODAL_OPEN_FOR_DETAILS'
export const SET_IS_MODAL_OPEN_FOR_GOODY_USER_INFORMATIONS = 'SET_IS_MODAL_OPEN_FOR_GOODY_USER_INFORMATIONS'
export const SET_NEEDED_FIELDS_FOR_GOODY = 'SET_NEEDED_FIELDS_FOR_GOODY'
export const SET_IS_ORDERING_GOODY = 'SET_IS_ORDERING_GOODY'
