import * as types from './mutation-types'

export function reset ({ commit }) {
  commit(types.RESET)
}

export async function fetchCardAndRewards ({ commit, dispatch, rootState }) {
  // commit(types.RESET)
  commit(types.SET_IS_LOADING, true)

  let cardSlot
  try {
    cardSlot = await this.$PE.client.getCardSlot()
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'card#getCardSlot' }, { store: this })
    commit(types.SET_ERROR, 'Une erreur inconnue est survenue.')
    commit(types.SET_IS_LOADING, false)
    return
  }

  commit(types.SET_CARD_SLOT, cardSlot)

  let coupons
  try {
    coupons = await this.$PE.client.getCardCatalog({ card: cardSlot.loyalty_card })
  } catch (e) {
    this.$airbrake.notifyFromKit(e, { locator: 'card#getCardCatalog' }, { store: this })
    commit(types.SET_ERROR, 'Une erreur inconnue est survenue.')
    commit(types.SET_IS_LOADING, false)
    return
  }

  commit(types.SET_COUPONS, coupons)

  commit(types.SET_IS_LOADED, true)
  commit(types.SET_IS_LOADING, false)
}
