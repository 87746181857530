import resetter from '../../utils/store/resetter'

import * as types from './mutation-types'

export const state = () => ({
  areSectionsLoading: false,
  areSectionsLoaded: false,
  sections: [],
  identifier: null,
  selectedCoupon: null,
  isOverlayOpen: false,
  openModalName: null,
  reselling: false,   // to display a loader on resell
})

export const actions = {
  reset ({ commit }) {
    commit(types.RESET)
  },

  async fetch ({ commit }) {
    commit(types.SET_ARE_SECTIONS_LOADING, true)
    const response = await this.$PE.client.getEarnedCoupons()
    commit(types.SET_SECTIONS, response.sections)
    commit(types.SET_ARE_SECTIONS_LOADED, true)
    commit(types.SET_ARE_SECTIONS_LOADING, false)
  },

  resell ({ commit, dispatch, state }) {
    commit(types.SET_RESELLING, true)

    if (state.selectedCoupon.resellable_as_points && state.selectedCoupon.resellable_as_fidzs) {
      this.$airbrake.notify({
        error: new Error('coupon with both resellable_as_points && resellable_as_fidzs'),
        params: { coupon: state.selectedCoupon },
      })
      commit(types.SET_RESELLING, false)
      return dispatch('addToastGenericError')
    }

    let promise
    if (state.selectedCoupon.resellable_as_points) {
      promise = this.$PE.client.resellEarnedCouponAsPoints({ coupon: state.selectedCoupon })
    }
    if (state.selectedCoupon.resellable_as_fidzs) {
      promise = this.$PE.client.resellEarnedCouponAsFidzs({ coupon: state.selectedCoupon })
    }
    return promise.then(
      () => {
        return dispatch('fetch').then(() => {
          commit(types.SET_RESELLING, false)
          commit(types.CLOSE_MODAL)
          this.$router.push('/profile/earned_coupons')
        })
      },
      () => {
        commit(types.SET_RESELLING, false)
        return dispatch('addToastGenericError')
      }
    )
  },
}

export const mutations = {
  [types.RESET]: resetter(state),

  [types.SET_SECTIONS] (state, sections) {
    state.sections = sections
  },
  [types.SET_ARE_SECTIONS_LOADING] (state, boolean) {
    state.areSectionsLoading = boolean
  },
  [types.SET_ARE_SECTIONS_LOADED] (state, boolean) {
    state.areSectionsLoaded = boolean
  },

  [types.SET_IDENTIFIER] (state, identifier) {
    state.identifier = identifier
  },
  [types.SET_RESELLING] (state, boolean) {
    state.reselling = boolean
  },

  [types.SELECT_REWARD] (state, coupon) {
    state.selectedCoupon = coupon
    state.isOverlayOpen = true
  },
  [types.CLOSE_OVERLAY] (state) {
    state.selectedCoupon = null
    state.isOverlayOpen = false
  },
  [types.OPEN_MODAL] (state, modalName) {
    state.openModalName = modalName
  },
  [types.CLOSE_MODAL] (state) {
    state.openModalName = null
  },
}
