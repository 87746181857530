import Vue from 'vue'

import { pluralize } from '../../plugins/global-filters'

import resetter from '../../utils/store/resetter'
import createInitialState from './state'

import * as types from './mutation-types'

export const CONTEXT_BUY = 'buy'
export const CONTEXT_CANCEL = 'cancel'

const COMPONENT_TAG_FOR_REWARD = 'ListCouponItem'
const COMPONENT_TAG_FOR_SEPARATOR = 'ListSeparator'

function upsertCouponSeparators (coupons) {
  const hydratedCoupons = coupons.filter(c => (c.componentTag === COMPONENT_TAG_FOR_REWARD))

  const indexTarget = hydratedCoupons.findIndex(reward => reward.is_next_goal)
  const index = hydratedCoupons.findIndex((reward, idx) =>
    reward.isBuyable &&
    hydratedCoupons[idx + 1] &&
    !hydratedCoupons[idx + 1].isBuyable
  )
  if (index !== -1) {
    hydratedCoupons.splice(index + 1, 0, {
      identifier: 'separator',
      componentTag: COMPONENT_TAG_FOR_SEPARATOR,
      text: (
        (indexTarget !== -1) && indexTarget > index
          ? 'Continuez à cumuler pour débloquer votre choix !'
          : "Pour débloquer d'autres cadeaux, continuez vos achats !"
      ),
      textUppercase: true,
    })
  }

  return hydratedCoupons
}

function hydratedCoupons (coupons) {
  const hydratedCoupons = coupons.map(reward => ({
    ...reward,
    componentTag: COMPONENT_TAG_FOR_REWARD,
    isBuyable: reward.missing_value === 0,
    isBuying: false,
    isCancellable: false,
    isCancelling: false,
    isGoalToggling: false,
    isFetchingNeededFields: false,
  }))
  return upsertCouponSeparators(hydratedCoupons)
}

function getNewValue (reward, context) {
  switch (context) {
    case CONTEXT_BUY:
      return reward.is_showable_as_a_discount
        ? reward.progression_next_value
        : reward.value
    case CONTEXT_CANCEL:
      return reward.value
    default:
      // TODO: alert on slack (should not happen)
      return reward.value
  }
}

export default {
  [types.RESET]: resetter(createInitialState),

  [types.SET_IS_SHOP_LOADING] (state, boolean) {
    state.isShopLoading = boolean
  },
  [types.SET_IS_SHOP_LOADED] (state, boolean) {
    state.isShopLoaded = boolean
  },

  [types.SET_CATALOG] (state, catalog) {
    state.catalog = catalog
  },
  [types.SET_COUPONS] (state, coupons) {
    state.hydratedCoupons = hydratedCoupons(coupons)
  },

  [types.UPDATE_REWARD_KEY] (_state, { reward, key, value }) {
    Vue.set(reward, key, value)
  },
  [types.UPDATE_REWARDS_VALUES] (state, { availablePoints, context }) {
    state.hydratedCoupons.forEach(reward => {
      const newValue = getNewValue(reward, context)
      const isBuyable = newValue <= availablePoints
      const missingValue = isBuyable ? 0 : newValue - availablePoints
      const formattedMissingValue = isBuyable
        ? 'Débloqué!'
        : pluralize(missingValue, `${missingValue} point manquant`, `${missingValue} points manquants`)

      Vue.set(reward, 'isBuyable', isBuyable)
      Vue.set(reward, 'missing_value', missingValue)
      Vue.set(reward, 'formatted_missing_value', formattedMissingValue)
    })
    state.hydratedCoupons = upsertCouponSeparators(state.hydratedCoupons)
  },
  [types.UPDATE_REWARD_SEPARATORS] (state) {
    state.hydratedCoupons = upsertCouponSeparators(state.hydratedCoupons)
  },
  [types.TOGGLE_REWARD_AS_NEXT_GOAL] (state, reward) {
    state.hydratedCoupons.forEach(r => {
      if (r.identifier === reward.identifier) {
        Vue.set(r, 'is_next_goal', !r.is_next_goal)
      } else {
        Vue.set(r, 'is_next_goal', false)
      }
    })
  },

  [types.SET_IS_MODAL_OPEN_FOR_DETAILS] (state, boolean) {
    state.isModalOpenForDetails = boolean
  },
  [types.SET_IS_MODAL_OPEN_FOR_GOODY_USER_INFORMATIONS] (state, boolean) {
    state.isModalOpenForGoodyUserInformations = boolean
  },
  [types.SELECT_REWARD] (state, reward) {
    state.selectedCoupon = reward
  },
  [types.SET_NEEDED_FIELDS_FOR_GOODY] (state, fields) {
    state.neededFieldsForGoody = fields
  },
  [types.SET_IS_ORDERING_GOODY] (state, boolean) {
    state.isOrderingGoody = boolean
  },
}
