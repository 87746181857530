export default () => ({
  isShopLoading: false,
  isShopLoaded: false,
  catalog: null,
  hydratedCoupons: [],

  isModalOpenForDetails: false,
  selectedCoupon: null,
  neededFieldsForGoody: null,
  isModalOpenForGoodyUserInformations: false,
  isOrderingGoody: false,
})
